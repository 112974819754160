/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

// Array of sections

export default [
  {
    title: 'แผงควบคุม',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'manage',
    resource: 'dashboard', // dashboard
  },
  {
    title: 'ประกาศ',
    route: 'announce',
    icon: 'FlagIcon',
    action: 'manage',
    resource: 'Auth', // Auth
  },
  {
    title: 'คู่มือการใช้งาน',
    route: 'how-to',
    icon: 'BookIcon',
    action: 'manage',
    resource: 'Auth', // Auth
  },
  {
    title: 'Presentation',
    href: 'https://www.canva.com/design/DAFcw0xZcWg/nAIvLm9FFneNXpWK7eqEig/view',
    icon: 'PlayCircleIcon',
    action: 'manage',
    resource: 'Auth', // Auth
  },
  {
    title: 'Affiliate',
    icon: '',
    action: 'manage',
    resource: 'admin',
    children: [
      {
        title: 'ผู้เข้าร่วม AF',
        route: 'affiliate-user-management',
        action: 'manage',
        resource: 'admin',
      },
      {
        title: 'ข้อมูลธุรกรรม AF',
        route: 'affiliate-transactions',
        action: 'manage',
        resource: 'admin',
      },
      {
        title: 'จัดการคำขอถอนเงิน AF',
        route: 'affiliate-withdraw-management',
        action: 'manage',
        resource: 'admin',
      },
    ],
  },
  {
    title: 'สรุปรายงาน',
    icon: 'ActivityIcon',
    action: 'manage',
    resource: 'marketing', // marketing
    children: [
      {
        title: 'เครดิต',
        route: 'report-credit',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      {
        title: 'การเข้าสู่ระบบ',
        route: 'report-user-login',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      {
        title: 'แบบสอบถาม',
        route: 'report-survey',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      {
        title: 'ธุกรรมแต่ละช่องทาง',
        route: 'report-providers-transaction',
        action: 'manage',
        resource: 'marketing',
      },
    ],
  },
  {
    title: 'จัดการข้อมูล สไลด์',
    route: 'slideManagement',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'marketing', // marketing
  },
  {
    title: 'ตรวจสอบวอลเปเปอร์',
    icon: 'ImageIcon',
    action: 'manage',
    resource: 'marketing', // marketing
    children: [
      {
        title: 'รออนุมัติ',
        route: 'images-management-review',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      {
        title: 'กำลังตรวจสอบ',
        route: 'images-management-checking',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      {
        title: 'อนุมัติ',
        route: 'images-management-approve',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      {
        title: 'ไม่อนุมัติ',
        route: 'images-management-reject',
        action: 'manage',
        resource: 'marketing', // marketing
      },
      // {
      //   title: 'รายละเอียดรูป',
      //   route: { name: 'images-management-details', params: { id: 0 } },
      //   action: 'manage',
      //   resource: 'marketing', // marketing
      // },
    ],
  },
  {
    title: 'ตรวจสอบราศี',
    icon: 'ImageIcon',
    route: 'zodiac-management',
    action: 'manage',
    resource: 'marketing', // marketing
  },
  {
    title: 'ข้อมูล ธุรกรรม',
    route: { name: 'UserTransactionData' },
    icon: 'TrendingUpIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'ข้อมูลการขอคืนเงิน',
    route: 'refund-management',
    icon: 'RepeatIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'ข้อมูลผู้ใช้งาน',
    route: 'userManagement',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'ข้อมูล ครีเอเตอร์',
    route: 'partners-management',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'marketing', // marketing
  },
  {
    title: 'จัดการคำขอ ถอนเงิน',
    route: 'withdraw-management',
    icon: 'PocketIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'จัดการข้อมูล การเติมเงิน',
    route: 'creditManagement',
    icon: 'CreditCardIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'จัดการ Redeem รูป',
    route: 'redeem-management',
    icon: 'TagIcon',
    resource: 'redeem-management', // redeem-management
    action: 'manage',
  },
  {
    title: 'จัดการ Redeem Coin',
    route: 'redeem-coin-management',
    icon: 'TagIcon',
    resource: 'admin', // admin
    action: 'all',
  },
  {
    title: 'ปักหมุดรูปภาพ',
    route: 'bookmark-management',
    icon: 'BookmarkIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    header: 'จัดการแจ้งเตือน',
    action: 'manage',
    resource: 'marketing', // marketing
  },
  {
    title: 'ส่งแจ้งเตือน',
    route: 'notificationData',
    icon: 'AlertTriangleIcon',
    action: 'manage',
    resource: 'marketing', // marketing
  },
  {
    title: 'ตั้งค่าการแจ้งเตือน',
    route: 'notificationSetting',
    icon: 'BellIcon',
    action: 'manage',
    resource: 'marketing', // marketing
  },
  {
    header: 'เครื่องมือต่างๆ',
    action: 'manage',
    resource: 'tools', // Auth
  },
  {
    title: 'Short Link',
    route: 'tools-short-link',
    icon: 'LinkIcon',
    action: 'manage',
    resource: 'tools', // Auth
  },
  {
    header: 'จัดการวอลเปเปอร์',
    action: 'manage',
    resource: 'wallpaper', // wallpaper
  },
  {
    title: 'วอลเปเปอร์ทั้งหมด',
    route: 'wallpapers',
    icon: 'PackageIcon',
    action: 'manage',
    resource: 'wallpaper', // wallpaper
  },
  {
    title: 'อัปโหลดวอลเปเปอร์',
    route: 'wallpaper-upload-wallpaper',
    icon: 'UploadIcon',
    action: 'manage',
    resource: 'wallpaper', // wallpaper
  },
  {
    title: 'อัปโหลดราศี',
    route: 'wallpaper-upload-zodiac',
    icon: 'UploadIcon',
    action: 'manage',
    resource: 'wallpaper', // wallpaper
  },
  {
    title: 'สร้างรูป',
    route: 'wallpaper-create',
    icon: 'ToolIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'ตั้งค่าอัปโหลด',
    icon: 'SlidersIcon',
    action: 'manage',
    resource: 'marketing', // marketing
    children: [
      {
        title: 'หมวดหมู่วอลเปเปอร์',
        route: 'wallpaper-setting-upload',
        action: 'manage',
        resource: 'marketing', // marketing
      },
    ],
  },
  {
    header: 'สรุปข้อมูลการขาย',
    action: 'manage',
    resource: 'wallpaper', // wallpaper
  },
  {
    title: 'ข้อมูลการขาย',
    route: 'wallpaper-sale-info',
    icon: 'MonitorIcon',
    action: 'manage',
    resource: 'wallpaper', // wallpaper
  },
  {
    header: 'ทั่วไป',
    resource: 'partner', // partner
    action: 'manage',
  },
  {
    title: 'ตั้งค่าบัญชี',
    route: 'partner-settings',
    icon: 'SettingsIcon',
    resource: 'partner-setting', // partner-setting
    action: 'manage',
  },
  {
    title: 'ถอนเงินจากบัญชี',
    icon: 'AlertTriangleIcon',
    route: 'partner-withdraw',
    resource: 'partner', // partner
    action: 'manage',
  },
  {
    title: 'ประวัติการถอนเงิน',
    icon: 'AlertTriangleIcon',
    route: 'partner-withdraw-transactions',
    resource: 'partner', // partner
    action: 'manage',
  },
  {
    title: 'ติดต่อสอบถาม',
    href: 'https://lin.ee/t3FGSRx',
    icon: 'MessageSquareIcon',
    resource: 'partner-setting', // partner-setting
    action: 'manage',
  },
  {
    header: 'Support',
    action: 'manage',
    resource: 'support', // support
  },
  {
    title: 'ข้อมูลลูกค้า',
    route: 'supports-customers',
    icon: 'UsersIcon',
    action: 'manage',
    resource: 'support', // support
  },
  {
    title: 'เติมเครดิต',
    route: 'supports-add-credit',
    icon: 'DollarSignIcon',
    action: 'manage',
    resource: 'support', // support
  },
  {
    header: 'จัดการแอป',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'ตั้งค่า',
    route: 'app-mobile-config',
    icon: 'AlertTriangleIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'i18n',
    route: 'app-mobile-i18n-config',
    icon: 'AlertTriangleIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    header: 'อื่นๆ',
    action: 'manage',
    resource: 'admin', // admin
  },
  {
    title: 'ส่งให้บัญชี',
    route: 'export-management',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'admin', // admin
  },
]
